import React, { useContext } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'gatsby';
import fetch from 'isomorphic-fetch';

import StyledFooter from './Footer-Styles';

import ApplicationContext from '../../particles/context/ApplicationContext';

const primaryDomain = `https://www.environmentcentre.com/`;

const Footer = ({ children }) => {
  const { state, dispatch } = useContext(ApplicationContext);

  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? '0' + s : s;
    }
    var d = new Date(inputFormat);
    return [
      pad(d.getDate()),
      pad(d.getMonth() + 1),
      d.getFullYear(),
    ].join('-');
  }

  const dateString = convertDate(new Date());

  const buildFront = async e => {
    e.preventDefault();
    const res = await fetch(`${process.env.GATSBY_NETLIFY_HOOK}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
    } else {
      console.error(res.statusText);
    }
  };

  const viewTable = e => {
    e.preventDefault();
    const key = 'tableView';
    const value = state.tableView ? !state.tableView : true;
    dispatch({ type: 'set', key, value });
  };

  const markerInfoArray =
    state && state.locationsQueried
      ? state.locationsQueried
      : state.locations && state.locations.length
      ? state.locations.slice(0, 1000)
      : [];

  return (
    <StyledFooter className="footer">
      <div className="footer__top">
        <nav>
          <h2 className="footer__product">tEC EPC Mapping Tool</h2>
        </nav>
      </div>
      <div className="footer__contents">
        <nav>
          <h4>Application</h4>
          <Link to="/instructions">Instructions</Link>
          <img
            src="https://api.netlify.com/api/v1/badges/a33da32d-2b6a-4a40-913b-8d35f28ab767/deploy-status"
            alt="Netlify build status"
          />
          {/*<button onClick={buildFront}>Rebuild Application</button>*/} 
                 </nav>
                <nav>
          <h4>Data and Policies</h4>
          <a
            href={`${primaryDomain}/about-us/privacy-policy/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          <a
            href={`${primaryDomain}/about-us/environmental-policy/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Environmental policy
          </a>
        </nav>
        <nav>
          <h4>the Environment Centre</h4>
          <a
            href={`${primaryDomain}/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Homepage
          </a>
          <a
            href={`${primaryDomain}/about-us/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            About tEC
          </a>
          <a
            href={`${primaryDomain}/about-us/the-team/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Meet The Team
          </a>
        </nav>
      </div>
      <div className="footer__bottom">
        <nav>
          <span className="footer__company">
             the Environment Centre is a registered charity No 1031482 | Environmental Body No 024168 | Company Limited by Guarantee No 2881225
           
            © Copyright the Environment Centre (tEC) 2009-2020
          </span>
          <span className="footer__credit">
            Developed by
            <a
              href="https://whatjackhasmade.co.uk"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jack Pritchard
            </a>
          </span>
        </nav>
      </div>
    </StyledFooter>
  );
};

export default Footer;
