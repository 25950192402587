import styled from 'styled-components';
import { device } from '../../particles/MediaQueries';

const StyledFooter = styled.footer`
  position: relative;
  z-index: 10;

  background-color: ${props => props.theme.grey900};
  color: ${props => props.theme.white};

  a,
  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    color: inherit;
    font: inherit;

    line-height: normal;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    color: ${props => props.theme.grey500};
    font-weight: 400;
    text-decoration: none;
    transition: 0.2s all ease;

    &:active,
    &:focus,
    &:hover {
      color: ${props => props.theme.white};
      transform: none;
    }

    svg {
      margin-right: 8px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 16px;

    color: ${props => props.theme.white};
    font-size: 16px;

    @media ${device.md} {
      margin-bottom: 20px;

      font-size: 18px;
    }

    ~ * + * {
      margin-top: 8px;

      @media ${device.md} {
        margin-top: 16px;
      }
    }
  }

  img {
    max-height: 21px;
  }

  nav {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  span {
    display: block;
  }

  .footer__bottom,
  .footer__contents,
  .footer__top {
    margin: 0 auto;
    max-width: 1506px;
    width: 100%;
  }

  .footer__contents {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 7px 30px;

    @media ${device.sm} {
      padding: 30px;
    }

    @media ${device.md} {
      align-items: flex-start;
      flex-direction: row;
    }
  }

  .footer__bottom {
    padding: 7px 30px;

    color: ${props => props.theme.grey500};

    @media ${device.sm} {
      padding: 0 30px 45px;
    }

    nav {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      border-top: 1px solid ${props => props.theme.grey700};

      @media ${device.sm} {
        padding-top: 30px;
      }
    }
  }

  .footer__credit {
    a {
      margin-left: 4px;
    }
  }

  .footer__product {
    margin: 0;

    font-size: 18px;
    text-transform: initial;

    @media ${device.md} {
      font-size: 20px;
    }
  }

  .footer__top {
    padding: 7px 30px;

    color: ${props => props.theme.grey500};

    @media ${device.sm} {
      padding: 45px 30px 0;
    }

    nav {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      border-bottom: 1px solid ${props => props.theme.grey700};

      @media ${device.sm} {
        padding-bottom: 30px;
      }
    }
  }
`;

export default StyledFooter;
