import React from 'react';
import styled from 'styled-components';

const NoSupport = styled.section`
  margin: 0 auto;
  max-width: 1506px;
  padding: 32px;
  width: 100%;

  text-align: left;

  a {
    font-size: 20px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 24px;
  }

  > * {
    max-width: 900px;
  }
`;

const SupportedList = () => (
  <ul>
    <li>
      <a href="https://www.mozilla.org/en-GB/firefox/new/">Firefox</a>
    </li>
    <li>
      <a href="https://www.google.co.uk/chrome/">Chrome</a>
    </li>
    <li>
      <a href="https://www.opera.com/">Opera</a>
    </li>
    <li>
      <a href="https://www.apple.com/uk/safari/">Safari</a>
    </li>
  </ul>
);

const NoSupportEdge = () => (
  <NoSupport>
    <h2>Sorry, We Don't Currently Support Microsoft Edge</h2>
    <p>
      Microsoft Edge does not include a core technology, IndexedDB in
      its software.
    </p>
    <p>
      This technology is required to build a local database for the
      mapping tool.
    </p>
    <p>
      Future versions of MSEdge (Microsoft Edge) are planned to
      include this technology but until then you will need to use an
      alternative browser.
    </p>
    <h3>Supported Browsers</h3>
    <SupportedList />
  </NoSupport>
);

const NoSupportIE = () => (
  <NoSupport>
    <h2>Sorry, We Don't Support Internet Explorer</h2>
    <p>
      Microsoft recently warned users that its once beloved Internet
      Explorer that a vulnerability in the browser allows actors to
      hijack the computers of those running the outdated program.
    </p>
    <p>
      For most users this isn't something to be immediately afraid of,
      but at your next opportunity please upgrade your browser.
    </p>
    <h3>Supported Browsers</h3>
    <SupportedList />
  </NoSupport>
);

export { NoSupportEdge };
export { NoSupportIE };
