import { createGlobalStyle } from 'styled-components';
import { device } from './MediaQueries';

const GlobalStyle = createGlobalStyle`
	html {
		box-sizing: border-box;
		overflow-y: scroll;

		font-family: ${props => props.theme.fontPrimary};
		font-size: 62.5%;

		/* BETTER FONT SMOOTHING - https://gist.github.com/hsleonis/55712b0eafc9b25f1944 */
		font-variant-ligatures: none;
		-webkit-font-variant-ligatures: none;
		text-rendering: optimizeLegibility;
		-moz-osx-font-smoothing: grayscale;
		font-smoothing: antialiased;
		-webkit-font-smoothing: antialiased;
		text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;

		@media ${device.MXmd} {
			&.scroll--fixed {
				overflow: hidden;
			}
		}
	}

	*, *:before, *:after {
		box-sizing: inherit;
	}


	/* Accessibly remove animations: https://gist.githubusercontent.com/bellangerq/6cdfe6e3701b4048c72546960c7c9f66/raw/dc5036697d0da57eff8e0f659106b319102e72a0/a11y-disable-animations.css */
	@media (prefers-reduced-motion: reduce) {
		*,
		*::before,
		*::after {
			animation-duration: 0.001ms !important;
			animation-iteration-count: 1 !important;
			transition-duration: 0.001ms !important;
		}
	}

	a {
		color: ${props => props.theme.primary};
		text-decoration: none;

		&:active,
		&:focus,
		&:hover {
			text-decoration: underline;
		}
	}

	/* Fix anchor scroll positioning */
  [id]::before {
    display: block;
    content: '';
    margin-top: -4rem;
    height: 4rem;
  }

	body {
		scroll-behavior: smooth;

		background-color: ${props =>
      props.mode === 'dark' ? props.theme.black : props.theme.white};
		color: ${props =>
      props.mode === 'dark' ? props.theme.white : props.theme.black};
		font-size: 1.6rem;
		line-height: 1.5;
	}

	main {
		flex: 1;

		/* IE Fix */
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			min-height: 100vh;
		}
	}

	.wrapper {
		@supports (display: flex) {
			display: flex;
			flex-direction: column;
			min-height: 100vh;
		}

		/* IE Fix */
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			height: 100vh;
		}
	}

	/* Common base styles for the site */
	img, svg, video {
		max-width: 100%;
	}



	/* FORM */
	::placeholder {
		color: ${props => props.theme.grey700};
		opacity: 1;
	}

	button,
	input[type="button"],
	input[type="submit"],
	.button {
    display: flex;
    margin: 12px 0 12px;
    padding: 6px;
    width: 100%;

    background-color: ${props => props.theme.primary};
    border: none;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: white;
    cursor: pointer;
    font-weight: 700;
    line-height: 1;
    outline: none;
    text-align: left;
    text-decoration: none;
 /*text-transform: capitalize */
    transition: all 0.15s ease;
    white-space: nowrap;
    width: auto;

    &:active,
    &:focus,
    &:hover {
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
        0 3px 6px rgba(0, 0, 0, 0.08);
      transform: translateY(-1px);
    }
	}

	.button--secondary {
		background-color: ${props => props.theme.secondary};

	}

	.button--tertiary {
		background-color: transparent;
		box-shadow: none;
		color: ${props => props.theme.grey700};

		&:active,
    &:focus,
    &:hover {
			box-shadow: none;
			color: ${props => props.theme.black};
      transform: translateY(0);
    }
	}

	button[disabled] {
		background: ${props => props.theme.grey400};
		border: none;
		cursor: not-allowed;
	}

	button[type="reset"] {
		background: transparent;
		border: 1px solid ${props =>
      props.mode === 'dark'
        ? props.theme.grey700
        : props.theme.grey400};
		color: ${props =>
      props.mode === 'dark' ? props.theme.white : props.theme.black};
		opacity: 0.5;

		&:active,
		&:focus,
		&:hover {
			opacity: 1;
		}
	}

  button,
  input,
  label,
  textarea {
    border-radius: 3px;
  }

  input,
  label,
  textarea {
    display: block;
    width: 100%;
  }

  input,
  textarea {
    padding: 16px;

    border: 1px solid #ccc;

    & + & {
      margin-top: 16px;
    }
  }

	input[readonly] {
		cursor: not-allowed;
	}

  label {
    margin: 16px 0 8px;
    font-weight: 500;
 /*text-transform: capitalize */
  }

	textarea {
		max-width: 100%;
		min-height: 200px;
		resize: vertical;
	}


	/* HEADINGS */
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 16px;
		margin-top: 0;

		font-weight: 600;
		line-height: 1.15;
	     /*text-transform: capitalize */
	}

	h1,
	.h1 {
		font-size: ${props => props.theme.headingTwo};
	}

	h2,
	.h2 {
		font-size: ${props => props.theme.headingThree};
	}

	h3,
	.h3 {
		font-size: ${props => props.theme.headingFour};
	}

	h4,
	.h4 {
		font-size: ${props => props.theme.headingFive};
	}

	h5,
	.h5 {
		font-size: ${props => props.theme.headingSix};
	}

	h6,
	.h6 {
		font-size: ${props => props.theme.headingTiny};
	}

	@media ${device.lg} {
		h1,
		.h1 {
			font-size: ${props => props.theme.headingOne};
		}
		h2,
		.h2 {
			font-size: ${props => props.theme.headingTwo};
		}
		h3,
		.h3 {
			font-size: ${props => props.theme.headingThree};
		}
		h4,
		.h4 {
			font-size: ${props => props.theme.headingFour};
		}
		h5,
		.h5 {
			font-size: ${props => props.theme.headingFive};
		}
		h6,
		.h6 {
			font-size: ${props => props.theme.headingSix};
		}
	}

	/* HR */

	hr {
		margin: 64px 20px;
		max-width: 1536px;
		padding: 0;
		width: calc(100% - 60px);

		border: none;
		border-top: medium solid ${props => props.theme.grey400};
		color: ${props => props.theme.grey400};

		@media ${device.md} {
			margin: 144px 30px;
		}

		@media screen and (min-width: 1536px) {
			margin: 144px auto;
		}
	}


	/* LIST */
	ol,
	ul {
		margin-top: ${props => props.theme.spacingDefault};
	}

	/* PARAGRAPH */
	p {
		margin-bottom: 0;
		margin-top: 0;
		font-size: 24px;

		& + * {
			margin-top: ${props => props.theme.spacingDefault};
		}
	}

	/* TABLE */
	table {
		border-collapse: collapse;
		max-width: 100%;
		table-layout: fixed;
		width: 100%;
		text-align: left;
		@media ${device.MXlg} {
			tr,
			th,
			td {
				display: block;
			}
		}
	}

	td,
	th {
		padding: ${props => props.theme.spacingDefault} ${props =>
  props.theme.spacingSmall};
	}

	td {
		&:before {
			@media ${device.MXlg} {
				content: attr(data-title) ": ";
			}
		}
	}

	th {
		background-color: ${props => props.theme.grey300};
		font-weight: ${props => props.theme.fontWeightBold};
		letter-spacing: 1px;
		text-transform: uppercase;
	}

	tr {
		background-color: ${props => props.theme.white};
		border-top: 1px solid ${props => props.theme.grey300};
		transition: background-color 0.2s ease;

		&:active,
		&:focus,
		&:hover {
			background-color: ${props => props.theme.grey200};

			.table__actions * {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.table__currency {
		text-align: right;
	}

	/* VIDEO */
	.video,
	video {
		max-width: 100%;
	}

	/* GRID */
	.grid {
		margin: 0 auto;
		max-width: 1506px;
		padding: 0 30px;
		width: 100%;

		@media ${device.md} {
			max-width: 1506px;
		}
	}

	.grid--short {
		max-width: 540px;
		padding: 30px;

		background-color: white;

		@media ${device.md} {
 			max-width: 580px;
		}
	}

	.title--animate {
		span {
			font-size: 0px;
			transition: 0.4s font-size ease;
		}

		&:hover {
			span {
				font-size: 64px;
			}
		}
	}

	.wrapper--center {
		main {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}

	.bold,
	.text--bold {
		font-weight: 700;
	}
`;

export default GlobalStyle;
