import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import Helmet from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { detect } from 'detect-browser';
import config from '../../../SiteConfig';

import 'normalize.css/normalize.css';

import ApplicationContext from '../particles/context/ApplicationContext';
import GlobalStyle from '../particles/GlobalStyle';
import getFirebase, { FirebaseContext } from '../particles/Firebase';
import withAuthentication from '../particles/Session/withAuthentication';
import { ThemeDefault } from '../particles/ThemeDefault';
import {
  NoSupportIE,
  NoSupportEdge,
} from '../particles/Support/NoSupport';

import Header from '../organisms/header/Header';
import Footer from '../organisms/footer/Footer';
import useWhyDidYouUpdate from '../../useWhyDidYouUpdate';

const queryFields = [
  { field: 'wallType', GraphQLField: 'WALLS_DESCRIPTION (A)' },
  { field: 'gasConnection', GraphQLField: 'MAINS_GAS_FLAG' },
  { field: 'heatingFuel', GraphQLField: 'MAIN_FUEL (B) (text)'},
  { field: 'heatingType', GraphQLField: 'MAINHEAT_DESCRIPTION (C) (text)' },
  { field: 'roofType', GraphQLField: 'ROOF_DESCRIPTION (D) (text)' },
  { field: 'propertyType', GraphQLField: 'PROPERTY_TYPE (K) (text)' },
  { field: 'builtForm', GraphQLField: 'BUILT_FORM (E) (text)' },
  { field: 'glazingType', GraphQLField: 'GLAZED_TYPE (F) (text)' },
  { field: 'transactionType', GraphQLField: 'TRANSACTION_TYPE (G) (text)' },
  { field: 'floorLevel', GraphQLField: 'FLOOR_LEVEL (H) (text)' },
  { field: 'localAuthority', GraphQLField: 'LOCAL_AUTHORITY_LABEL' },
  
];

// Params: newState, filteredLocations, field, GraphQLField, jsonOptions
// e.g. field = 'wallType'
// e.g. GraphQLField = 'WALLS_DESCRIPTION'

const queryFilter = (nS, fL, field, gqlField) => {
  if (nS[field] && !nS[field].includes('all')) {
    fL = fL.filter(loc => {
      const fieldString = loc[gqlField].toLowerCase();

      const isValid = nS[field].some(type => {
        return type === fieldString;
      });

      return isValid ? loc : false;
    });
  }

  return fL;
};

const applicationReducer = (state, action) => {
  const { key, type, value, values } = action;

  switch (type) {
    case 'set': {
      const newState = { ...state };
      newState[key] = value;
      if (
        typeof window !== 'undefined' &&
        key !== 'locations' &&
        key !== 'search'
      )
        localStorage.setItem(key, JSON.stringify(value));
      newState.loading = false;
      return newState;
    }
    case 'setMultiple': {
      const newState = { ...state };
      Object.keys(values).forEach((key, index) => {
        const value = values[key];
        newState[key] = value;
        if (typeof window !== 'undefined')
          localStorage.setItem(key, JSON.stringify(value));
      });
      newState.loading = false;
      return newState;
    }
    case 'loading': {
      const newState = { ...state };
      newState.loading = true;
      return newState;
    }
    case 'query': {
      if (process.env.GATSBY_DEBUGGING) console.clear();
      const newState = { ...state };
      const allLocations = newState.locations;
      var filteredLocations = allLocations;

      if (process.env.GATSBY_DEBUGGING) console.log({ allLocations });
      if (process.env.GATSBY_DEBUGGING) console.time('filtering');
      if (process.env.GATSBY_DEBUGGING) console.log({ newState });

      // Query data using Efficiency Rating
      if (newState.epcRatings) {
        filteredLocations = allLocations.filter(loc => {
          const [min, max] = newState.epcRatings;
          const efficiency = parseInt(loc.CURRENT_ENERGY_EFFICIENCY);
          if (efficiency <= max && efficiency >= min) return loc;
          return false;
        });
      }

      queryFields.map(obj => {
        filteredLocations = queryFilter(
          newState,
          filteredLocations,
          obj.field,
          obj.GraphQLField,
        );
        return obj;
      });

      if (newState.addressSearch && newState.addressSearch !== '') {
        filteredLocations = filteredLocations.filter(loc => {
          const addressString = loc.fulladdress.toLowerCase();
          const searchString = newState.addressSearch.toLowerCase();

          return addressString.includes(searchString);
        });
      }

      if (process.env.GATSBY_DEBUGGING) console.timeEnd('filtering');
      if (process.env.GATSBY_DEBUGGING)
        console.log({ filteredLocations });

      newState.locationsQueried = filteredLocations.length
        ? filteredLocations
        : [];

      newState.loading = false;
      return newState;
    }
    default: {
      return state;
    }
  }
};

// Local storage check function
const lsCheck = (fallbackValue, key) => {
  if (typeof window === 'undefined') return fallbackValue;
  const val = JSON.parse(localStorage.getItem(key));
  if (!val) return fallbackValue;
  return val;
};

const Default = props => {
  const [state, dispatch] = useReducer(applicationReducer, {
    address: lsCheck('', 'address'),
    city: lsCheck('', 'city'),
    epcRatings: lsCheck([0, 100], 'epcRatings'),
    KmlLayer: null,
    loading: false,
    locations: [],
    locationsQueried: undefined,
    mapCenter: undefined,
    postcode: lsCheck('', 'postcode'),
    search: lsCheck('', 'search'),
    tableIndex: 0,
    theme: lsCheck('light', 'theme'),
    town: lsCheck('', 'town'),
  });
  const [firebase, setFirebase] = useState(null);
  useWhyDidYouUpdate('Context', state);

  useEffect(() => {
    const app = import('firebase/app');
    const auth = import('firebase/auth');
    const database = import('firebase/database');

    Promise.all([app, auth, database]).then(values => {
      const firebase = getFirebase(values[0]);
      setFirebase(firebase);
    });
  }, []);

  return (
    <FirebaseContext.Provider value={firebase}>
      <ApplicationContext.Provider value={{ state, dispatch }}>
        <AppWithAuthentication {...props} />
      </ApplicationContext.Provider>
    </FirebaseContext.Provider>
  );
};

const AppWithAuthentication = withAuthentication(
  ({ align, children, page }) => {
    const context = useContext(ApplicationContext);
    const state = context ? context.state : undefined;

    const browser = detect();

    const renderChildren = children => {
      // handle the case where we don't detect the browser
      switch (browser && browser.name) {
        case 'chrome':
        case 'firefox':
        case 'safari':
        case 'opera':
          return children;

        case 'edge':
          return <NoSupportEdge />;

        case 'ie':
          return <NoSupportIE />;

        default:
          return children;
      }
    };

    return (
      <ThemeProvider theme={ThemeDefault}>
        <React.Fragment>
          <GlobalStyle mode={state ? state.theme : 'light'} />
          <Helmet title={config.siteTitle} />
          <div
            className={`wrapper wrapper--${page} wrapper--${align}`}
          >
            <Header />
            <main>{renderChildren(children)}</main>
            <Footer />
          </div>
        </React.Fragment>
      </ThemeProvider>
    );
  },
);

export default Default;
export { GlobalStyle };
