import React, { useContext } from 'react';
import { Link } from 'gatsby';
import StyledHeader from './Header-Styles';

import Logo from '../../../assets/images/logo.png';

import ApplicationContext from '../../particles/context/ApplicationContext';

import { AuthUserContext } from '../../particles/Session';
import SignOutButton from '../../particles/SignOut';
import * as ROUTES from '../../particles/constants/routes';

const Header = () => {
  const { state, dispatch } = useContext(ApplicationContext);
  var headerShow =
    state && state.headerShow ? state.headerShow : false;

  const toggleHeader = e => {
    e.preventDefault();

    dispatch({
      type: 'set',
      key: 'headerShow',
      value: !headerShow,
    });
  };

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <StyledHeader mode={state.theme}>
          <div className="header__contents">
            <Link to={authUser ? ROUTES.HOME : ROUTES.LANDING}>
              <img src={Logo} alt="the Environment Centre Logo" />
            </Link>
            <button className="header__toggle" onClick={toggleHeader}>
              {headerShow ? `Hide` : `Show`} Menu
            </button>
            <nav className="header__navigation">
              {authUser ? (
                <NavigationAuth authUser={authUser} />
              ) : (
                <NavigationNonAuth />
              )}
            </nav>
          </div>
        </StyledHeader>
      )}
    </AuthUserContext.Consumer>
  );
};

const NavigationAuth = ({ authUser }) => (
  <>
    <Link to={ROUTES.HOME}>Home</Link>
    <Link to={ROUTES.INSTRUCTIONS}>Instructions</Link>
    <Link to={ROUTES.INSTALL}>Install</Link>
    <Link to={ROUTES.LICENCE}>Licence</Link>
    {/* <Link to={ROUTES.ACCOUNT}>Account</Link> */}
    <SignOutButton />
  </>
);

const NavigationNonAuth = () => (
  <>
    <Link to={ROUTES.LANDING}>Landing</Link>
    <Link to={ROUTES.SIGN_IN}>Sign In</Link>
  </>
);

export default Header;
