const ThemeDefault = {
  /* COLOURS */
  black: '#141213',
  grey1000: '#0d0d0d',
  grey900: '#262626',
  grey800: '#3d4852',
  grey700: '#606f7b',
  grey600: '#8795a1',
  grey500: '#b8c2cc',
  grey400: '#dae1e7',
  grey300: '#e9ebec',
  grey200: '#eeeff0',
  grey100: '#f9f9f9',
  greyNeutral800: '#404040',
  greyNeutral700: '#595959',
  greyNeutral600: '#737373',
  greyNeutral500: '#8c8c8c',
  greyNeutral400: '#a6a6a6',
  greyNeutral300: '#bfbfbf',
  greyNeutral200: '#d9d9d9',
  greyNeutral100: '#f2f2f2',
  white: '#ffffff',

  red900: '#671918',
  red800: '#912322',
  red700: '#ba2c2c',
  red600: '#ba2c2c',
  red500: '#d03736',
  red400: '#dd6f6e',
  red300: '#e79898',
  red200: '#f0c1c1',
  red100: '#faeaea',

  blue900: '#133039',
  blue800: '#20505F',
  blue700: '#2D7085',
  blue600: '#3788A1',
  blue500: '#54AAC5',
  blue400: '#7ABDD2',
  blue300: '#A0D0DF',
  blue200: '#C6E3EC',
  blue100: '#ECF6F9',

  green900: '#0C411B',
  green800: '#136C2D',
  green700: '#1B973E',
  green600: '#23C250',
  green500: '#3DDC6A',
  green400: '#68E48B',
  green300: '#93ECAC',
  green200: '#BEF3CD',
  green100: '#E9FBEE',
  greentec: '#d7e4bd',

  primary: '#23C250',
  secondary: '#3788A1',
  tertiary: '#4348a3',

  /* SHADOWS */
  bs: '0 12px 24px 0 rgba(0, 0, 0, 0.09)',

  /* SPACING UNITS */
  spacingTiny: '4px',
  spacingSmall: '8px',
  spacingDefault: '16px',
  spacingLarge: '32px',
  spacingHuge: '64px',

  /* BORDERS */
  borderRadiusDefault: '3px',

  /* SPACING */
  headingOne: '72px',
  headingTwo: '60px',
  headingThree: '48px',
  headingFour: '36px',
  headingFive: '24px',
  headingSix: '18px',
  headingTiny: '16px',

  /* FONT */
  fontPrimary: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`,
  fontWeightDefault: '400',
  fontWeightBold: '500',
};

export { ThemeDefault };
