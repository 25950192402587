import styled from 'styled-components';
import { device } from '../../particles/MediaQueries';

const StyledHeader = styled.header`
  position: relative;

  color: ${props =>
    props.mode === 'dark' ? props.theme.white : props.theme.black};
  background-color: ${props =>
    props.mode === 'dark'
      ? props.theme.grey900
      : props.theme.greentec};

  img {
    width: 160px;
  }

  nav {
    display: flex;
    flex-direction: row;

    a,
    button {
      border: none;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;

      background: transparent;

      color: inherit;
      font: inherit;

      line-height: normal;

      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;

      -webkit-appearance: none;

      &::-moz-focus-inner {
        border: 0;
        padding: 0;
      }

      padding: 6px 8px;
      position: relative;

      box-shadow: none;
      color: ${props =>
        props.mode === 'dark'
          ? props.theme.grey500
          : props.theme.grey700};
      font-weight: 400;
      text-decoration: none;
      transition: 0.2s all ease;

      &:active,
      &:focus,
      &:hover {
        color: ${props =>
          props.mode === 'dark'
            ? props.theme.white
            : props.theme.black};
        transform: none;
      }

      &[aria-current='page'] {
        &:after {
          bottom: 0;
          content: '';
          display: block;
          height: 1px;
          left: 8px;
          position: absolute;
          width: calc(100% - 16px);

          background-color: ${props =>
            props.mode === 'dark'
              ? props.theme.white
              : props.theme.black};
        }
      }
    }
  }

  .header__contents {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 7px 30px;

    @media ${device.sm} {
      padding: 3px 32px 0px 32px;
    }
  }

  .header__toggle {
    @media ${device.sm} {
      display: none;
    }
  }
`;

export default StyledHeader;
